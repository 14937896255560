import { Box } from '@mui/material'
import React from 'react'
import { Header } from '../Header'
import { useDeviceInfo } from 'src/modules/device/hooks'
import { Color } from 'src/styles/color'
import { Footer } from '../Footer'
import { useIsLargeWidthDevice } from 'src/modules/layout'

export interface LayoutProps {
  children: React.ReactNode
  boxHeight?: string
  hideDrawer?: boolean
}
export const Layout: React.FC<LayoutProps> = ({ children, boxHeight = '100vh', hideDrawer = false }) => {
  const { isFromNativeApp } = useDeviceInfo()
  const isLargeDevice = useIsLargeWidthDevice()

  return (
    <Box
      paddingTop={isLargeDevice ? '24px' : 0}
      bgcolor={Color.blackAlpha50}
      sx={{ display: 'flex', flexDirection: 'column', height: boxHeight, width: 1 }}
    >
      <Box
        bgcolor="white"
        maxWidth="512px"
        minHeight={'95vh'}
        width={1}
        height={'100%'}
        marginX="auto"
        borderRadius={isLargeDevice ? '16px' : 0}
        sx={{ display: 'flex', flexDirection: 'column' }}
      >
        {!isFromNativeApp && (
          <Box sx={{ flex: '0 1 auto' }}>
            <Header hideDrawer={hideDrawer} />
          </Box>
        )}
        {children}
      </Box>
      <Box bgcolor={isLargeDevice ? undefined : 'white'}>
        <Footer />
      </Box>
    </Box>
  )
}
