import { useL10n } from '@singcolor/l10n'
import { useCallback, useMemo } from 'react'
import { ExternalPath, Path } from 'src/modules/path'
import { useGrpcClient } from 'src/modules/grpc/hooks'
import { ListAvailableWebFeaturesV1Response } from '@singcolor/grpc-client/src/singcolor-proto/ts/users_v1_pb'
import { SWRKey } from 'src/modules/swr/key'
import useSWR from 'swr'

export type AppEnvType = 'local' | 'dev1' | 'dev2' | 'load-test' | 'qa1' | 'qa2' | 'qa3' | 'prod'
export const getAppEnv = () => {
  return (process.env.NEXT_PUBLIC_APP_ENV || 'dev1') as AppEnvType
}

export const useListListAvailableWebFeatures = () => {
  const { userClient } = useGrpcClient()
  const func = useCallback(async () => {
    const data = (await userClient.listAvailableWebFeatures()) as ListAvailableWebFeaturesV1Response.AsObject
    return data.availableFeaturesList
  }, [userClient])

  return useSWR(SWRKey.listAvaliableFeatures(), func)
}

export const useGetNavigationMenuList = () => {
  const l10n = useL10n()
  const { data: availableFeaturesList } = useListListAvailableWebFeatures()
  return useMemo(() => {
    if (!availableFeaturesList) return []
    return [
      // 換金
      {
        label: l10n.exchangeMoney,
        path: Path.exchangeHome,
        show: availableFeaturesList?.some(k => k === ListAvailableWebFeaturesV1Response.Feature.WITHDRAW_DIAMOND)
      },
      // ダイヤ明細
      {
        label: l10n.diamondReport,
        path: Path.diamondDetail,
        show: availableFeaturesList?.some(k => k === ListAvailableWebFeaturesV1Response.Feature.DIAMOND_STATEMENT)
      },
      // コインを安く購入
      {
        label: l10n.purchaseWebCoinCheap,
        path: Path.coins,
        show: availableFeaturesList?.some(k => k === ListAvailableWebFeaturesV1Response.Feature.WEB_COIN_PURCHASE)
      },
      // Lシンガー統計情報
      {
        label: l10n.liverReport,
        path: Path.statistic,
        show: availableFeaturesList?.some(k => k === ListAvailableWebFeaturesV1Response.Feature.STATISTICS)
      },
      {
        label: '困った時には',
        path: ExternalPath.faq,
        show: true
      }
    ].filter(item => item.show)
  }, [availableFeaturesList, l10n.diamondReport, l10n.exchangeMoney, l10n.liverReport, l10n.purchaseWebCoinCheap])
}
