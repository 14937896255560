import React from 'react'
import { Box, Drawer as MuiDrawer } from '@mui/material'
import { Typography } from '@singcolor/components/esm/core/Typography'
import Link from 'next/link'
import { useSignOut } from 'src/modules/auth/hooks'
import { useGetNavigationMenuList } from 'src/utils/app'

export interface DrawerProps {
  open: boolean
  onClose: () => void
}
export const Drawer = ({ open, onClose }: DrawerProps) => {
  const { handleSignOut } = useSignOut()
  const menus = useGetNavigationMenuList()
  return (
    <MuiDrawer open={open} onClose={onClose}>
      <Box sx={{ width: '256px' }}>
        {/* ロゴ */}
        <Box sx={{ px: '16px', py: '12px' }}>
          <img src="/assets/logo.png" height="28px" width="auto" onClick={handleSignOut} />
        </Box>

        {menus.map(item => {
          return (
            <Box key={item.label}>
              <Link
                onClick={() => onClose()}
                href={item?.path || '/'}
                style={{
                  color: 'rgba(0, 0, 0, 0.8)',
                  textDecoration: 'none',
                  height: '48px',
                  display: 'flex',
                  alignItems: 'center',
                  paddingRight: '16px',
                  paddingLeft: '16px'
                }}
                prefetch
              >
                <Typography sx={{ fontSize: '16px', lineHeight: '24px', fontWeight: 400 }}>{item.label}</Typography>
              </Link>
            </Box>
          )
        })}

        <Box sx={{ px: '16px', mt: '16px' }}>
          <Box
            onClick={() => {
              const ok = window.confirm('ログアウトしますか')
              if (ok) {
                handleSignOut().then(() => window.alert('ログアウトしました。'))
              }
            }}
          >
            <Typography sx={{ color: 'rgba(0, 0, 0, 0.6)', textDecoration: 'none' }}>{'ログアウト'}</Typography>
          </Box>
        </Box>
      </Box>
    </MuiDrawer>
  )
}
