import { Box, Link, Stack, useMediaQuery } from '@mui/material'
import { useRouter } from 'next/router'
import React from 'react'
import { useL10n } from '@singcolor/l10n'
import { useIsLargeWidthDevice } from 'src/modules/layout'
import { Path } from 'src/modules/path'
import { Color } from 'src/styles/color'
import { useWebLink } from 'src/modules/web_links/hooks'

export const Footer: React.FC = () => {
  const l10n = useL10n()
  const isLargeWidthDevice = useIsLargeWidthDevice()
  // リンクとcopy rightsを横並びに表示するかどうか widthの数字は特に決められてないので適当
  const linksAndCopyRightsShouldBeShownHorizontally = useMediaQuery('(min-width:712px)')
  const webLinks = useWebLink()
  const { pathname } = useRouter()

  const privacyPolicy = <LinkItem href={webLinks.privacyPolicy}>{l10n.privacyPolicy}</LinkItem>
  const terms = <LinkItem href={webLinks.userTerms}>{l10n.terms}</LinkItem>
  const faq = <LinkItem href={webLinks.fAQHome}>{l10n.faq}</LinkItem>
  const corporation = <LinkItem href={webLinks.corporation}>{l10n.company}</LinkItem>
  const copyrights = (
    <Box color={Color.blackAlpha600} fontSize="12px" lineHeight={1.5} textAlign="center">
      {l10n.copyright}
    </Box>
  )

  const padding = '16px 24px'

  if (!isLargeWidthDevice) {
    // SPはログインページのみで出す
    if (!pathname.startsWith(Path.authSignIn)) return <></>
    return (
      <Box padding={padding}>
        <Stack spacing="8px" direction="column" alignItems="center" justifyContent="center">
          <Stack spacing="16px" direction="row">
            {privacyPolicy}
            {terms}
          </Stack>
          <Stack spacing="16px" direction="row">
            {faq}
            {corporation}
          </Stack>
        </Stack>
        <Box mt="16px" />
        {copyrights}
      </Box>
    )
  }

  if (linksAndCopyRightsShouldBeShownHorizontally) {
    return (
      <Box padding={padding} display="flex" flexDirection="row" justifyContent="space-between">
        <Stack spacing="16px" direction="row" alignItems="center" justifyContent="center">
          {privacyPolicy}
          {terms}
          {faq}
          {corporation}
        </Stack>

        {copyrights}
      </Box>
    )
  }

  return (
    <Box padding={padding}>
      <Stack spacing="16px" direction="row" alignItems="center" justifyContent="center">
        {privacyPolicy}
        {terms}
        {faq}
        {corporation}
      </Stack>

      <Box mt="16px" />
      {copyrights}
    </Box>
  )
}

const LinkItem: React.FC<{ href: string; children: React.ReactNode }> = ({ href, children }) => {
  return (
    <Link
      href={href}
      target="_blank"
      color={Color.blackAlpha600}
      sx={{ textDecoration: 'none' }}
      fontSize="12px"
      lineHeight={1.5}
    >
      {children}
    </Link>
  )
}
