import { useCallback, useEffect } from 'react'
import { atom, useRecoilState } from 'recoil'
import cookie from 'js-cookie'

const COOKIE_KEY = 'from_native'
const COOKIE_VALUE = '1'

type State = {
  isFromNativeApp: boolean
}

const deviceInfoState = atom<State>({
  key: 'deviceInfo',
  default: {
    isFromNativeApp: false
  }
})

export const useDeviceInfo = () => {
  const [state, setState] = useRecoilState(deviceInfoState)

  const setIsFromNativeApp = useCallback(() => {
    setState(s => ({ ...s, isFromNativeApp: true }))
    cookie.set(COOKIE_KEY, COOKIE_VALUE)
  }, [setState])

  useEffect(() => {
    if (cookie.get(COOKIE_KEY) === COOKIE_VALUE) {
      setIsFromNativeApp()
    }
  }, [setIsFromNativeApp])

  return { ...state, setIsFromNativeApp }
}
